










































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { DateTime, Duration } from 'luxon'
import SelectedService from '../models/SelectedService'
import { localTimeStringToUtcString } from '../utils/dateUtils'
import Booking from '../models/Booking'
import Portal from '../models/Portal'
import AvailableTime from '../models/AvailableTime'
import CustomerInfo from '../models/CustomerInfo'
import Reference from '../models/Reference'
import CreateBooking, { CreateBookingError } from '../utils/CreateBooking'

const DateFormat = 'yyyy-MM-dd'
const DateTimeFormat = 'yyyy-MM-dd HH:mm:ss'

@Component({})
export default class Calendar extends Vue {
  @Prop({ type: SelectedService, required: true })
  private selectedService: SelectedService

  @Prop({ type: Portal, required: false })
  private portal: Portal

  @Prop({ type: CustomerInfo, required: false })
  private customer: CustomerInfo

  @Prop({ type: String, required: true })
  private timezone: string

  @Prop({ type: Boolean, required: false })
  private isAdmin: boolean

  @Prop({ type: Reference, required: false })
  private reference: Reference

  @Prop({ type: Booking, required: false })
  private modifyExistingBooking: Booking

  private duration = 0
  private availableTimesInternal = {}
  private maxWeekDay = 0

  private today: DateTime = null
  private loading = true
  private availableTimesError = false
  private times = []
  private errorDialog = {
    title: '',
    text: '',
    visible: false,
  }

  public created(): void {
    this.today = DateTime.now()
  }

  private get calendarId(): number {
    return this.portal ? this.portal.calendarId : null
  }

  private get portalId(): number {
    return this.portal ? this.portal.id : null
  }

  // =============================================================================
  // Load available times
  // These are the basis for various state, that we update each time they change
  // =============================================================================

  @Watch('url')
  public loadAvailableTimes(): void {
    // todo: consolidate with CalendarMobile
    this.availableTimesError = false
    if (
      !this.selectedService.serviceId ||
      !this.selectedService.placeId ||
      !this.startDateTimeString ||
      !this.endDateTimeString ||
      !this.portalId
    ) {
      this.onAvailableTimesChange([])
      this.loading = false
      return
    }
    this.loading = true
    this.$axios
      .get(this.url)
      .then((response) => {
        this.duration = response.data.data.duration
        this.onAvailableTimesChange(response.data.data.available)
        this.loading = false
      })
      .catch((err) => {
        this.onAvailableTimesChange([])
        this.availableTimesError = true
        this.loading = false
        console.error('Error fetching available times: ' + err.response?.data?.error?.message, err)
      })
  }

  private onAvailableTimesChange(availableTimes: Array<AvailableTime>): void {
    let maxWeekDay = 0
    const available = {}
    const distinct = {}

    for (let i = 0; i < availableTimes.length; i++) {
      const dt = DateTime.fromISO(availableTimes[i].start.replace(' ', 'T'), { zone: 'UTC' })
      if (dt.weekday > maxWeekDay) {
        maxWeekDay = dt.weekday
      }
      const key = dt.setZone(this.timezone).toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
      available[key] = 1
      const t = key.split('T')[1].replace(/:00$/, '')
      distinct[t] = 1
    }
    this.availableTimesInternal = available
    this.maxWeekDay = maxWeekDay

    const times = []
    for (const key in distinct) {
      times.push(key)
    }
    times.sort()
    this.times = times
  }

  // =============================================================================
  // Params for available times
  // Changes in these results in a new url that triggers loading new available times
  // =============================================================================

  private get url(): string {
    let params =
      '' +
      '?serviceId=' +
      this.selectedService.serviceId +
      '&placeId=' +
      this.selectedService.placeId +
      '&startTime=' +
      this.startDateTimeString +
      '&endTime=' +
      this.endDateTimeString
    for (let i = 0; i < this.selectedService.addonServiceIds.length; i++) {
      params += '&addonServiceIds[]=' + this.selectedService.addonServiceIds[i]
    }
    return '/v4/booking/portals/' + this.portalId + '/available-times' + params
  }

  private get startDateTime(): DateTime {
    if (!this.today) {
      return null
    }
    const time =
      this.today.weekday > 1 ? this.today.minus(Duration.fromISO('P' + (this.today.weekday - 1) + 'D')) : this.today
    return DateTime.fromISO(time.toFormat(DateFormat) + 'T00:00:00', { zone: 'UTC' })
  }

  private get endDateTime(): DateTime {
    if (!this.today) {
      return null
    }
    const time =
      this.today.weekday < 7 ? this.today.plus(Duration.fromISO('P' + (7 - this.today.weekday) + 'D')) : this.today
    return DateTime.fromISO(time.toFormat(DateFormat) + 'T23:59:59', { zone: 'UTC' })
  }

  private get startDateTimeString(): string {
    const time = this.startDateTime
    return time ? time.toFormat(DateTimeFormat) : ''
  }

  private get endDateTimeString(): string {
    const time = this.endDateTime
    return time ? time.toFormat(DateTimeFormat) : ''
  }

  // =============================================================================
  // Computed getters
  // =============================================================================

  private get days(): Array<{ date: string; day: string }> {
    if (!this.duration || !this.startDateTime || !this.endDateTime) {
      return []
    }
    let start = DateTime.fromISO(this.startDateTime.toFormat(DateFormat) + 'T00:00:00', { zone: 'UTC' })
    const end = DateTime.fromISO(this.endDateTime.toFormat(DateFormat) + 'T00:00:00', { zone: 'UTC' })
    const result = []
    while (start <= end) {
      if (start.weekday > this.maxWeekDay) {
        break
      }
      result.push({
        date: start.toFormat(DateFormat),
        day: this.$t(start.weekdayLong),
      })
      start = start.plus(Duration.fromISO('P1D'))
    }
    return result
  }

  private get weekNumber(): string {
    return this.today ? '' + this.today.weekNumber : ''
  }

  private get hasAvailableTimes(): boolean {
    return Object.keys(this.availableTimesInternal).length > 0
  }

  // =============================================================================
  // Helpers
  // =============================================================================

  private getAvailableStatus(time: string, date: string): boolean {
    const key = date + 'T' + time + ':00'
    return !!this.availableTimesInternal[key]
  }

  // =============================================================================
  // Click action handlers
  // =============================================================================

  private get canGoToPreviousWeek(): boolean {
    const selectedWeek = this.today ? this.today.weekNumber : 0
    const selectedYear = this.today ? this.today.year : 0

    const currentDate = DateTime.now()
    const currentWeek = currentDate.weekNumber
    const currentYear = currentDate.year

    return selectedWeek > currentWeek || selectedYear > currentYear
  }

  private clickNextWeek(): void {
    this.today = this.today.plus(Duration.fromISO('P1W'))
  }

  private clickPreviousWeek(): void {
    if (!this.canGoToPreviousWeek) {
      return
    }
    this.today = this.today.minus(Duration.fromISO('P1W'))
  }

  private clickChooseTime(time: string, date: string): void {
    this.loading = true
    const dateTimeStringUtc = localTimeStringToUtcString(date + 'T' + time, this.timezone)

    if (this.modifyExistingBooking) {
      CreateBooking.update(
        this.$axios,
        this.calendarId,
        dateTimeStringUtc,
        this.selectedService,
        this.modifyExistingBooking,
        (err: CreateBookingError, booking: Booking) => {
          if (err) {
            this.errorDialog.title = '' + this.$t(err.title)
            this.errorDialog.text = '' + this.$t(err.text)
            this.errorDialog.visible = true
          } else {
            this.$emit('input', booking)
          }
          this.loading = false
        },
      )
    } else {
      CreateBooking.create(
        this.$axios,
        this.calendarId,
        this.portalId,
        dateTimeStringUtc,
        this.selectedService,
        this.customer,
        this.reference,
        this.isAdmin,
        (err: CreateBookingError, booking: Booking) => {
          if (err) {
            this.errorDialog.title = '' + this.$t(err.title)
            this.errorDialog.text = '' + this.$t(err.text)
            this.errorDialog.visible = true
          } else {
            this.$emit('input', booking)
          }
          this.loading = false
          this.loadAvailableTimes()
        },
      )
    }
  }

  private clickBack(): void {
    this.$emit('back')
  }
}
