
















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import CustomerInfo from '../models/CustomerInfo'
import TyreHotel from '../models/TyreHotel'

@Component({})
export default class LicenseplateCollector extends Vue {
  @Prop({ type: CustomerInfo, required: true })
  private value: CustomerInfo

  @Prop({ type: Number, required: false })
  private calendarId: number

  @Prop({ type: String, required: false })
  private countryName: string

  private tyreHotel: TyreHotel = new TyreHotel()
  private carDescription = ''
  private error = { title: '', details: '' }
  private loading = false
  private attemptSubmit = false

  private licensePlateHint = ''

  private get hasInfo(): boolean {
    return !!(this.value.licenseplate && this.value.mobilePhone)
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private validate(isReset = false): boolean {
    // This is to let first-step fields know they should start showing err if not valid,
    // because we don't want to do that before they click the button.
    this.attemptSubmit = !isReset
    // Trigger visual update of the inputs & check validness
    return this.form.validate()
  }

  private validateRequired(_field: string, value: string): string | boolean {
    value = (value || '').trim()
    if (this.attemptSubmit && (!value || value === '0')) {
      return this.$t('Field is required') as string
    }
    return true
  }

  private onLicensePlateBlur() {
    if (!this.value.licenseplate) {
      this.licensePlateHint = null
      return
    }
    this.$axios
      .get('/v4/booking/calendars/' + this.calendarId + '/validate-licenseplate/' + this.value.licenseplate)
      .then((response) => {
        if (response.data.valid) {
          this.licensePlateHint = null
        } else {
          this.licensePlateHint = this.$t("Warning: This doesn't look like a valid plate number. Are you sure you entered it correctly?")
        }
      })
      .catch((err) => {
        this.licensePlateHint = this.$t("Warning: This doesn't look like a valid plate number. Are you sure you entered it correctly?")
      })
  }

  private submit(): void {
    const callback = () => {
      this.$emit('value', this.value)
      this.$emit('tyre-hotel', this.tyreHotel)
      this.$emit('submit')
    }
    if (this.validate()) {
      this.loading = true
      const url =
        '/v4/booking/calendars/' +
        this.calendarId +
        '/lookup?licenseplate=' +
        encodeURIComponent(this.value.licenseplate) +
        '&mobilePhone=' +
        encodeURIComponent(this.value.mobilePhone)
      this.$axios
        .get(url)
        .then((response) => {
          this.value.found = response.data.data.found
          if (response.data.data.name || response.data.data.email) {
            // only if we got a "person", else let "remember me" do its thing
            this.value.name = response.data.data.name
            this.value.email = response.data.data.email
          }
          this.carDescription = response.data.data.carDescription
          this.tyreHotel.bookingServiceId = response.data.data.tyreHotelBookingServiceId
          this.tyreHotel.productDescription = response.data.data.tyreHotelProductDescription
          this.loading = false
          callback()
        })
        .catch((err) => {
          this.loading = false
          switch (err.response?.data?.error?.code) {
            case 'InvalidMobilePhone':
              this.error.title = '' + this.$t('Invalid mobile phone')
              this.error.details = (
                '' + this.$t('Tip: Use a mobile number valid in %s, or use international notation (like +4712345678)')
              ).replace('%s', '' + this.$t('c:country:' + this.countryName))
              break
            default:
              this.error.title = '' + this.$t('Unknown error ocurred')
              this.error.details = '' + this.$t('Please try again later')
          }
        })
    }
  }
}
